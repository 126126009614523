
.mask-container{
    position: relative;
}

.cask-container {
    position: relative
}

.color-image {
    position: absolute;
    inset: 0;
    background-image: url(/public/Assets/OldFiltersmørrebrød.png);
    background-size: cover;

    -webkit-mask-image: url(/public/Assets/mask.png);
    mask-image: url(/public/Assets/mask.png);
    -webkit-mask-image: url(/public/Assets/image-mask.png);
    mask-image: url(/public/Assets/image-mask.png);
    -webkit-mask-size: cover;
    mask-size: cover;

    -webkit-mask-position: 0% 0%;
    mask-position: 0% 0%;
    animation: addColor 2500ms steps(24) forwards  ;
    animation-delay: 2.5s;
}

.color-image2 {
    position: absolute;
    inset: 0;
    background-image: url(/public/Assets/håndmad.jpg);
    background-size: cover;

    -webkit-mask-image: url(/public/Assets/mask.png);
    mask-image: url(/public/Assets/mask.png);
    -webkit-mask-image: url(/public/Assets/image-mask.png);
    mask-image: url(/public/Assets/image-mask.png);
    -webkit-mask-size: cover;
    mask-size: cover;

    -webkit-mask-position: 0% 0%;
    mask-position: 0% 0%;
    animation: addColor 2500ms steps(24) forwards  ;
    animation-delay: 2.5s;
}

.mask-img {
    -webkit-mask-image: linear-gradient(black, transparent);
    mask-image: linear-gradient (black, transparent);
}



@keyframes addColor {
    0% {
        -webkit-mask-position: 0% 0%;
        mask-position: 0% 0%;
    }
    100% {
        -webkit-mask-position: 100% 0%;
        mask-position: 100% 0%;
    }
}
