@font-face {
    font-family: Edo;
    src: url(/public/Assets/edosz.ttf);
  }

@font-face {
    font-family: GreatVibes;
    src: url(/public/Assets/GreatVibes-Regular.ttf);
  }

@font-face {
    font-family: TrueCrimes;
    src: url(/public/Assets/crimes.ttf);
  }

  @font-face {
    font-family: Rubik;
    src: url(/public/Assets/RubikVinyl-Regular.ttf);
  }

  @font-face {
    font-family: Pacifico;
    src: url(/public/Assets/Pacifico-Regular.ttf);
  }
  
  @font-face {
    font-family: Carattere;
    src: url(/public/Assets/Carattere-Regular.ttf);
  }

  @font-face {
    font-family: Antihero;
    src: url(/public/Assets/Antihero.otf);
  }
  
  @font-face {
    font-family: Ego;
    src: url(/public/Assets/DIRTYEGO.TTF);
  }

  @font-face {
    font-family: Ricks;
    src: url(/public/Assets/RicksAmericanNF.ttf);
  }

  .Edo {
    font-family: Edo;
  }

  .Vibes {
    font-family: GreatVibes;
  }

  .Crimes {
    font-family: TrueCrimes;
  }

  .Rubik {
    font-family: Rubik;
  }

  .Carattere {
    font-family: Carattere;
  }

  .Pacifico {
    font-family: Pacifico;
  }

  .Antihero {
    font-family: Antihero;
  }

  .Ego {
    font-family: Ego;
  }

  .Ricks {
    font-family: Ricks;
  }

  .Bebas {
    font-family: 'Bebas Neue', sans-serif;
  }