

.nav {
    position: fixed;
    z-index: 20;

}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* gap: 25vw; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0em;
    background: black;  
}

.nav-title {
    color: white;
    font-size: 25px;
    /* font-family: 'Bebas Neue', sans-serif; */
    /* width: 60vw; */
    text-align: center;
}

.menu-toggle {
    cursor: pointer;
}

.nav-overlay {
    position: fixed;
    background: black;
    z-index: -10;
    bottom: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 1em;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
    font-family: 'Bebas Neue', sans-serif;
    
}

.hamBox {
    position: relative;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease;
    padding: 2.4em;
}

.hamBox:hover {
    
}

.hamBoxOpen {
   
}

.lineTop, .lineBottom {
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    border-color: white;
    background: rgba(255, 255, 255, 0.959);
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.lineTop {
    top: 2.2em;
}

.lineBottom {
    bottom: 2.2em;
}

/* .svg {
    top: 2.2em;
} */

.lineTop.spin {
    top: 2.35em;
    transform: rotate(135deg);
    background: #fff; 
}

.lineBottom.spin {
    bottom: 2.30em;
    transform: rotate(225deg);
    background: #fff; 
}