
.Preloader {
    height: 100vh;
    width: 100%;
    /* background-color: black; */
    background-image: url("/public/Assets/BgLoader.png");
    /* color: greenyellow; */
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.logo-container {
    width: 50vw;
    
}